<template>
  <div class="settingsPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <b-tabs>
          <b-tab title="Setting" active>
            <div class="card">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-md-12 mt-3 mb-3">
                    <h4 class="h4">Twilio</h4>
                    <p>Twilio Information not editable after setup. Contact Support if you need to change these values.
                    </p>
                  </div>
                  <div class="col-md-12">
                    <div v-if="twilioConfig" class="row">
                      <div class="col-md-6">
                        <TextInput v-model="twilioConfig.sid" label="Account sid" :readonly="true" />
                      </div>
                      <div class="col-md-6">
                        <TextInput v-model="twilioConfig.token" label="Auth token" :readonly="true" />
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-md-12">
                        <b-button variant="primary" :disabled="loadingUpdate" type="button"
                          @click="showTwilioConfigModal = true">
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else><i class="uil uil-upload-alt"></i> Set Twilio Config</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12 mt-3 mb-3">
                    <h4 class="h4">SendGrid</h4>
                    <p>Allow system to send email using Sendgrid.</p>
                  </div>
                  <div class="col-md-12">
                    <div v-if="sendgridIntegration" class="row">
                      <div class="col-md-6">
                        <TextInput v-model="sendgridIntegration.api_key" label="Api Key" :readonly="true" />
                      </div>
                      <div class="col-md-6">
                        <TextInput v-model="sendgridIntegration.options.from_email" label="From Email" :readonly="true" />
                      </div>
                      <!-- <div class="col-md-6 mb-5">
                        <label>Enabled</label>
                        <b-form-checkbox v-model="sendgridIntegration.active" name="check-button" switch
                          @change="handleChangeActive('sendgrid')">
                        </b-form-checkbox>
                      </div> -->
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <b-button variant="primary" :disabled="loadingUpdate" type="button"
                          @click="showSendgridIntegrationModal = true">
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else><i class="uil uil-upload-alt"></i> {{ sendgridIntegration ? 'Update' : 'Set' }}
                            SendGrid Config</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12 mt-3 mb-1">
                    <h4 class="h4">Auto Replies</h4>
                    <p>Auto replies are sent out from the system.</p>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <TextAreaAction v-model="settings.double_optin_message" label="Double Option Message" :rows="2"
                          :show-mergefield="true" :sms-length="true" :hide-title="true" :show-redemption="false"
                          :hide-expire-date="true" :hide-upload="true" :hide-contact-dropdown="true"
                          :hide-samcart-dropdown="true" :hide-stripe-dropdown="true" dropdown-size="sm" name="doubleOptinMessage" />
                      </div>
                      <div class="col-md-6">
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <b-button variant="primary" :disabled="loadingUpdate" type="button" @click="updateSetting">
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else><i class="uil uil-upload-alt"></i>Update</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Billing">
            <Billing />
          </b-tab>
          <b-tab title="Carrier Fees">
            <Carrier />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <b-modal v-model="showTwilioConfigModal" title="Set Twilio Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="twilioForm">
        <form class="twilio-form" @submit.prevent="handleSubmit(updateTwilio)">
          <div class="row">
            <div class="col-md-6">
              <TextInput v-model="twilio.sid" name="TwilioSid" rules="required" label="Account sid" />
            </div>
            <div class="col-md-6">
              <TextInput v-model="twilio.token" name="TwilioToken" rules="required" label="Auth token" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showTwilioConfigModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showSendgridIntegrationModal" title="Set SendGrid Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="sendgridForm">
        <form class="sendgrid-form" @submit.prevent="handleSubmit(updateSendgrid)">
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="sendgrid.api_key" name="apiKey" label="Api Key" />
            </div>
            <div class="col-md-12">
              <TextInput v-model="sendgrid.options.from_email" type="email" name="fromEmail" label="From Email" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showSendgridIntegrationModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Billing from './Billing'
import Carrier from './Carrier'

export default {
  components: {
    Billing, Carrier,
  },

  data() {
    return {
      loadingUpdate: false,
      showTwilioConfigModal: false,
      showSendgridIntegrationModal: false,
      user: {
        twilioConfig: {},
        sendgridIntegration: {
          options: {}
        },
      },
      twilio: {},
      sendgrid: {
        options: {
        },
      },
      settings: {},
    }
  },

  computed: {
    twilioConfig() {
      return this.user && this.user.twilioConfig
    },

    sendgridIntegration() {
      return this.user && this.user.sendgridIntegration
    },
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.initData();
  },

  methods: {
    initData() {
      this.twilio = {}
      this.sendgrid = {
        options: {
        },
      }
      this.settings = {
        double_optin_message: this.user.settings.double_optin_message
      }
    },
    updateSetting() {
      const param = {
        settings: this.settings,
      }

      this.loadingUpdate = true
      this.$store
        .dispatch('user/updateSetting', param)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },
    updateTwilio() {
      this.loadingUpdate = true;
      this.$store
        .dispatch('user/updateTwilio', this.twilio)
        .then(() => {
          this.loadingUpdate = false
          this.showTwilioConfigModal = false
          this.initData()
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    updateSendgrid() {
      this.loadingUpdate = true;
      this.$store
        .dispatch('user/updateSendgrid', this.sendgrid)
        .then((res) => {
          if (res.api_key)
            this.user.sendgridIntegration = res
          else
            this.user.sendgridIntegration = null

          this.loadingUpdate = false
          this.showSendgridIntegrationModal = false
          this.initData()
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    handleChangeActive(type) {
      switch (type) {
        case 'sendgrid':
          if (this.sendgridIntegration)
            this.changeIntegrationStatus(this.sendgridIntegration.id)
          break;
        default:
          break;
      }
    },

    changeIntegrationStatus(id) {
      this.$store
        .dispatch('integration/toggleStatus', id)
        .then((integration) => {
        })
        .catch(() => {
        })
    }
  }
}
</script>